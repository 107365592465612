/* eslint-disable no-undef */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const manifest = self.__WB_MANIFEST;
import { contextPathMap } from "./views/Messages/utils";

self.addEventListener("install", function () {
  self.skipWaiting();
});

/* 
  Richer notifications:
  https://developers.google.com/web/fundamentals/push-notifications/display-a-notification 
*/
self.addEventListener("push", function (event) {
  console.log(`[SW] Push Received: ${event.data.text()}`);

  try {
    const pushData = event.data.json();
    if (pushData.type === "new_message") {
      const { user, content, ...data } = pushData;
      const tag = data.context;

      event.waitUntil(
        self.registration.getNotifications({ tag }).then((notifications) => {
          let body = content;
          data.count = 1;
          const notification = notifications[0];
          if (notification !== undefined) {
            data.count = notification.data.count + 1;
            body = `${data.count} uleste meldinger`;
          }

          self.registration.showNotification(
            `Melding fra ${user.preferred_name || user.full_name}`,
            { body, data, tag, icon: "/notification-icon.png" }
          );
        })
      );
    }
  } catch (error) {
    /* Do nothing, no json = no notification */
  }
});

self.addEventListener("notificationclick", function (event) {
  console.log("[SW] Notification clicked", event.notification.data);

  const data = event.notification.data;
  const context = contextPathMap[data.receiver_context][data.context];
  const url = `/meldinger/${data.sale_id}/${context}`;

  event.notification.close();
  event.waitUntil(
    clients.matchAll({ type: "window" }).then((clientsArr) => {
      const existingClients = clientsArr.filter((windowClient) =>
        windowClient.url.endsWith(url)
      );

      if (existingClients.length) {
        const existingClient = existingClients[0];
        existingClient.postMessage({ action: "invalidate_conversation" });
        return existingClient.focus();
      } else {
        return clients.openWindow(url);
      }
    })
  );
});
